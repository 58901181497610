<template>

	<div class="site-wrapper no-header">

		
	
		<div class="container">

      <div class="buttons">
        <!-- <button class="button is-skip" @click="nextStep()">Skip to Part 9 &nbsp;<span><font-awesome-icon icon="chevron-right" /></span></button> -->
        <button class="button is-info" @click="goBack()"><span><font-awesome-icon icon="chevron-left" /></span> &nbsp;Back &nbsp;</button>
      </div>

        <div class="before-icon"><font-awesome-icon icon="calendar-check" /></div><h4 class="icon">{{editing ? 'Edit your' : 'Add a'}} Date</h4>  

			  <div class="">

	          <div class="form-group-reg">   
              <!-- <label class="label-fixed">Alert Title:</label>    -->
              <input class="input" type="text" placeholder="Date title..." v-model="newDate.title">
            </div>
	          <div class="form-group">      
              <textarea v-model="newDate.details" class="textarea" placeholder="Details (optional)" rows="5"></textarea>
            </div>

            <div class="select is-primary">
              <select v-model="newDate.dateType">
                <option disabled value="">Select type of date</option>
                <option v-for="type in dateTypes" :value="type" :key="type">{{ type }}</option>
              </select>
            </div>


						<div class="form-group">   
							<span>&nbsp; &nbsp;</span>
							<datetime v-model="newDate.expiryDate"></datetime>   
	            <!-- <input class="input" type="text" v-model="newDate.expiry" required> -->
	            <span class="highlight"></span>
	            <span class="bar"></span>
	            <label>Date:</label>
	          </div>
	          
            <div class="buttons">
           		<button v-if="newDate.title != '' && newDate.expiryDate != ''" class="button is-primary" @click="saveNewDate()">Save Date</button>
              <span class="button" v-else>Add a title and expiry date</span>
              <button v-if="editing" class="button is-danger" @click="deleteDate()">Delete Date</button>
            </div>


			  </div>
			  

		</div>


	</div>
	
			

</template>

<script>	

	import { db } from '../main.js';
	import firebase from 'firebase/app';
	import 'firebase/auth'; 
	import 'firebase/firestore';
	import NProgress from 'nprogress';
	import * as moment from 'moment'

	export default {

		name: 'AddDate',
    props: {
      sentDate: Object,
      editing: Boolean
    },
		mounted: function() {
			firebase.auth().onAuthStateChanged( this.onUserLogin );		
      this.newDate.id = this.sentDate.id ? this.sentDate.id : ''; 
      this.newDate.title = this.sentDate.title;	
      this.newDate.details = this.sentDate.details; 
      this.newDate.dateType = this.sentDate.dateType ? this.sentDate.dateType : ''; 
      // let tempDate = moment.utc(this.sentDate.expiryDate);
      // let fixedDate = this.formatDate(tempDate, "D MMM YYYY");
      // console.log('Fixed:',fixedDate);
      // this.newDate.expiryDate = fixedDate; 
      // if (this.sentDate.expiryDate == undefined) {
      //   console.log('empty');
      // } else {
      //   console.log('loaded');
      // }
      this.newDate.expiryDate = this.sentDate.expiryDate == undefined ? '' : this.sentDate.expiryDate.toISOString();
		},
		computed: {
      currentTruckID() {
        return this.$store.getters.getCurrentTruckID;
      },
      currentTruckName() {
        return this.$store.getters.getCurrentTruckName;
      },
      userData() {
        return this.$store.getters.getSubscriptionInfo;
      },
		},
		data: function() {
			return {
				msg: 'Add Alert',
				firebaseid: '',
				newDate: {
          id: '',
          title: '',
          details: '',
          dateType: '',
          expiryDate: ''
        },
        dateTypes: ['General Item','Safety Equipment','Scheduled Maintenance','Truck Inspection','Safety Drill','Other Date'],
			}
		},
		methods: {
			onUserLogin: function( user ) {
      	if (user) {      		
	      	this.firebaseid = user.uid;
	      }
	    },

      formatDate : function (sentDate,format) {
          return moment(sentDate).format(format);
      },

			saveNewDate: function() {
      	// NProgress.start();
      	var self = this;
      	let tempDate = (self.newDate.expiryDate ? self.newDate.expiryDate : new Date());
        // let finalDate = moment(tempDate).format('D MMMM YYYY');
        let finalDate = moment(tempDate).toDate();
        let humanDate = this.formatDate(self.newDate.expiryDate, "D MMMM YYYY");
        console.log(finalDate);

        if (self.editing) {

            db.collection('trucks').doc(self.currentTruckID).collection('importantDates').doc(self.newDate.id).update({
                dateType: self.newDate.dateType ? self.newDate.dateType : '',
                details: self.newDate.details ? self.newDate.details : '',
                expiryDate: finalDate,
                humanDate: self.newDate.expiryDate ? humanDate : '',
                logDate: firebase.firestore.FieldValue.serverTimestamp(),
                title: self.newDate.title ? self.newDate.title : ''
            })
            .then(() => {
                var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
                addTruckRevision({
                  userName: self.userData.username,
                  userID: self.firebaseid,
                  truckName: self.currentTruckName,
                  truckID: self.currentTruckID,
                  revisionDetails: 'New important date updated',
                });
                NProgress.done();
                self.goBack();
            })
            .catch(error => {
                console.error("Error adding date: ", error);
                NProgress.done();
                self.goBack();
            });

        } else {

            db.collection('trucks').doc(self.currentTruckID).collection('importantDates').add({
                dateType: self.newDate.dateType ? self.newDate.dateType : '',
                details: self.newDate.details ? self.newDate.details : '',
                expiryDate: finalDate,
                humanDate: self.newDate.expiryDate ? humanDate : '',
                logDate: firebase.firestore.FieldValue.serverTimestamp(),
                title: self.newDate.title ? self.newDate.title : ''
            })
            .then(() => {
                var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
                addTruckRevision({
                  userName: self.userData.username,
                  userID: self.firebaseid,
                  truckName: self.currentTruckName,
                  truckID: self.currentTruckID,
                  revisionDetails: 'New important date added',
                });
                NProgress.done();
                self.goBack();
            })
            .catch(error => {
                console.error("Error adding date: ", error);
                NProgress.done();
                self.goBack();
            });

        }

      	

      },

      deleteDate() {
        // console.log('Deleting date...', this.newDate.id);
        let self = this;    
        let message = {
          title: 'Delete this date?',
          body: ''
        };
        let options = {
          okText: 'Yes, Delete',
          backdropClose: true,
          cancelText: 'Cancel',
        };
        this.$dialog
          .confirm(message, options)
          .then(function() {

              NProgress.start();  
            
              db.collection('trucks').doc(self.currentTruckID).collection('importantDates').doc(self.newDate.id).delete()
              .then(() => {
                  var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
                  addTruckRevision({
                    userName: self.userData.username,
                    userID: self.firebaseid,
                    truckName: self.currentTruckName,
                    truckID: self.currentTruckID,
                    revisionDetails: 'Important date deleted',
                  });
                  NProgress.done();
                  self.goBack();
              })
              .catch(error => {
                  console.error("Error deleting date: ", error);
                  NProgress.done();
                  self.goBack();
              });
              
          })
          .catch(function() {
            console.log('Clicked on cancel'); 
            NProgress.done();
          });
      },


      goBack: function() {
        // console.log('Router',this.$router);
        this.$router.go(-1);
      },


		},

	}

</script>



<style lang="scss" scoped>

	.message {
		.message-body {
			background: #fff;
		}
	}
	textarea {
		min-height: 100px !important;
		box-shadow: none;
    padding: 0.6rem 1rem;
    &.notes {
    	margin-top: 1rem;
    }
	}
  .form-group {
    input[type="text"] {
      padding-top: 35px;
    } 
    span {
      height: 30px;
      display: block;
    }
  }


</style>
<template>
	
		<div>

        <div class="fleet-wrapper">

      		  <div class="main-fleet-content">

                <div v-if="currentTruckID">

                    <ul class="scroller">
                      <li>
                        <router-link to="my-dashboard">Dashboard</router-link>
                      </li>
                      <li>
                        <router-link to="truck-edit-sms">Truck Details</router-link>
                      </li>
                      <li class="is-active">
                        <a aria-current="page">Service Schedules</a>
                      </li>
                    </ul>

                    <br>
                    <div class="before-icon">
                      <font-awesome-icon icon="truck" />
                    </div>
                    <h4 class="icon">Service Schedules</h4>
                    <div class="clear"></div>

          				  
          				  <div class="tabs profile is-fullwidth is-centered">
          				    <ul class="service-table">
          				      <li :class="[ profileTab === 'serviceA' ? 'is-active' : '']">
          				      	<a @click="profileTab='serviceA'"><font-awesome-icon icon="wrench" />{{serviceAname}}</a>
          				      </li>
          				      <li :class="[ profileTab === 'serviceB' ? 'is-active' : '']">
          				      	<a @click="profileTab='serviceB'"><font-awesome-icon icon="wrench" />{{serviceBname}}</a>
          				      </li>
                        <li :class="[ profileTab === 'serviceC' ? 'is-active' : '']">
                          <a @click="profileTab='serviceC'"><font-awesome-icon icon="wrench" />{{serviceCname}}</a>
                        </li>
          				    </ul>
          				  </div>

          				  <div class="tab-content">

          				  	<div v-if="profileTab === 'serviceA'">

          				  		<p>&nbsp;</p>

          						  <table class="table is-fullwidth is-bordered">
          						  	<tr>
          						  		<th>Item</th>
          						  		<th>Details</th>
          						  	</tr>
          						  	<tr v-if="serviceWarning || serviceOverdue">
          						  		<td>Service</td>
          						  		<td>
          						  			<span class="tag is-warning" v-if="serviceWarning"> <font-awesome-icon icon="exclamation-triangle" />&nbsp;Due</span>
          						  			<span class="tag is-danger" v-if="serviceOverdue"> <font-awesome-icon icon="exclamation-triangle" />&nbsp;Overdue</span>
          						  		</td>
          						  	</tr>
                          <tr>
                            <td>Service name</td>
                            <td>{{serviceAname}} <span @click="editItemName('serviceAname',serviceAname)" class="button is-small">Edit</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Service type (Engine Hours or KM)</td>
                            <td>{{serviceAunit}} <span @click="editServiceUnit('Service A Type','serviceAunit',serviceAunit)" class="button is-small">Edit</span>
                            </td>
                          </tr>
          						  	<tr>
          						  		<td>Service interval</td>
          						  		<td>{{numberWithCommas(serviceAinterval)}}  {{(serviceAunit)}} <span @click="editItem('Service A Interval','serviceAinterval',serviceAinterval, serviceAunit)" class="button is-small">Edit</span>
          							  	</td>
          						  	</tr>
          						  	<tr>
          						  		<td>Latest reading</td>
          						  		<td>{{numberWithCommas(serviceAlatestReading)}}  {{(serviceAunit)}} <span @click="editItem('Latest Reading','serviceAlatestReading',serviceAlatestReading, serviceAunit)" class="button is-small">Edit</span>
          						  		</td>
          						  	</tr>
          						  	<tr>
          						  		<td>Last service date</td>
          						  		<td>{{serviceAlastServiceDate}}</td>
          						  	</tr>
          						  	<tr>
          						  		<td>{{(serviceAunit)}} at last service</td>
          						  		<td>{{serviceAlastServiceValue}}  {{(serviceAunit)}}</td>
          						  	</tr>
          						  	<tr>
          						  		<td>Next service due at</td>
          						  		<td>{{serviceAnextServiceValue}} {{(serviceAunit)}}</td>
          						  	</tr>

          						  </table>

          						  <h3 class="is-size-5">Service A History</h3>

          						  <ul v-if="serviceHistoryMain.length" class="sms-steps steps is-vertical has-content-centered">

          					    	<li class="steps-segment" v-for="(service, index) in serviceHistoryMain" :key="index">
          					        <span class="steps-marker is-primary">
          					        	<span class="icon">{{index+1}}</span>
          					        </span>
          					        <div class="steps-content">
          					          	<p class="heading">{{service.timestamp}}</p>
          					          	<span class="button is-small" @click="showServiceDetails(service)">View Details</span>
          					          	<p>&nbsp;</p>
          					        </div>
          					      </li>		      

          					    </ul>

          					    <div v-else>No service history.</div>

          						</div>

          						<div v-if="profileTab === 'serviceB'">

                        <p>&nbsp;</p>

                        <table class="table is-fullwidth is-bordered">
                          <tr>
                            <th>Item</th>
                            <th>Details</th>
                          </tr>
                          <tr v-if="serviceWarning || serviceOverdue">
                            <td>Service</td>
                            <td>
                              <span class="tag is-warning" v-if="serviceWarning"> <font-awesome-icon icon="exclamation-triangle" />&nbsp;Due</span>
                              <span class="tag is-danger" v-if="serviceOverdue"> <font-awesome-icon icon="exclamation-triangle" />&nbsp;Overdue</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Service name</td>
                            <td>{{serviceBname}} <span @click="editItemName('serviceBname',serviceBname)" class="button is-small">Edit</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Service type (Engine Hours or KM)</td>
                            <td>{{serviceBunit}} <span @click="editServiceUnit('Service A Type','serviceBunit',serviceBunit)" class="button is-small">Edit</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Service interval</td>
                            <td>{{numberWithCommas(serviceBinterval)}}  {{(serviceBunit)}} <span @click="editItem('Service A Interval','serviceBinterval',serviceBinterval, serviceBunit)" class="button is-small">Edit</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Latest reading</td>
                            <td>{{numberWithCommas(serviceBlatestReading)}}  {{(serviceBunit)}} <span @click="editItem('Latest Reading','serviceBlatestReading',serviceBlatestReading, serviceBunit)" class="button is-small">Edit</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Last service date</td>
                            <td>{{serviceBlastServiceDate}}</td>
                          </tr>
                          <tr>
                            <td>{{(serviceBunit)}} at last service</td>
                            <td>{{serviceBlastServiceValue}}  {{(serviceBunit)}}</td>
                          </tr>
                          <tr>
                            <td>Next service due at</td>
                            <td>{{serviceBnextServiceValue}} {{(serviceBunit)}}</td>
                          </tr>
                          
                        </table>

                        <h3 class="is-size-5">Service B History</h3>

                        <ul v-if="serviceHistoryMain.length" class="sms-steps steps is-vertical has-content-centered">

                          <li class="steps-segment" v-for="(service, index) in serviceHistoryMain" :key="index">
                            <span class="steps-marker is-primary">
                              <span class="icon">{{index+1}}</span>
                            </span>
                            <div class="steps-content">
                                <p class="heading">{{service.timestamp}}</p>
                                <span class="button is-small" @click="showServiceDetails(service)">View Details</span>
                                <p>&nbsp;</p>
                            </div>
                          </li>         

                        </ul>

                        <div v-else>No service history.</div>

                      </div>


                      <div v-if="profileTab === 'serviceC'">

                        <p>&nbsp;</p>

                        <table class="table is-fullwidth is-bordered">
                          <tr>
                            <th>Item</th>
                            <th>Details</th>
                          </tr>
                          <tr v-if="serviceWarning || serviceOverdue">
                            <td>Service</td>
                            <td>
                              <span class="tag is-warning" v-if="serviceWarning"> <font-awesome-icon icon="exclamation-triangle" />&nbsp;Due</span>
                              <span class="tag is-danger" v-if="serviceOverdue"> <font-awesome-icon icon="exclamation-triangle" />&nbsp;Overdue</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Service name</td>
                            <td>{{serviceCname}} <span @click="editItemName('serviceCname',serviceCname)" class="button is-small">Edit</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Service type (Engine Hours or KM)</td>
                            <td>{{serviceCunit}} <span @click="editServiceUnit('Service A Type','serviceCunit',serviceCunit)" class="button is-small">Edit</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Service interval</td>
                            <td>{{numberWithCommas(serviceCinterval)}}  {{(serviceCunit)}} <span @click="editItem('Service A Interval','serviceCinterval',serviceCinterval, serviceCunit)" class="button is-small">Edit</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Latest reading</td>
                            <td>{{numberWithCommas(serviceClatestReading)}}  {{(serviceCunit)}} <span @click="editItem('Latest Reading','serviceClatestReading',serviceClatestReading, serviceCunit)" class="button is-small">Edit</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Last service date</td>
                            <td>{{serviceClastServiceDate}}</td>
                          </tr>
                          <tr>
                            <td>{{(serviceCunit)}} at last service</td>
                            <td>{{serviceClastServiceValue}}  {{(serviceCunit)}}</td>
                          </tr>
                          <tr>
                            <td>Next service due at</td>
                            <td>{{serviceCnextServiceValue}} {{(serviceCunit)}}</td>
                          </tr>
                          
                        </table>

                        <h3 class="is-size-5">Service C History</h3>

                        <ul v-if="serviceHistoryMain.length" class="sms-steps steps is-vertical has-content-centered">

                          <li class="steps-segment" v-for="(service, index) in serviceHistoryMain" :key="index">
                            <span class="steps-marker is-primary">
                              <span class="icon">{{index+1}}</span>
                            </span>
                            <div class="steps-content">
                                <p class="heading">{{service.timestamp}}</p>
                                <span class="button is-small" @click="showServiceDetails(service)">View Details</span>
                                <p>&nbsp;</p>
                            </div>
                          </li>         

                        </ul>

                        <div v-else>No service history.</div>

                      </div>

          					</div>

                    <hr>
                    <div class="buttons">  
                      <router-link to="truck-edit-sms">
                        <span class="button is-info">Back to Truck</span>
                      </router-link>
                    </div>

                </div>

                <div v-else>
                  <div class="">
                    <p>No truck detected.</p>
                    <p>&nbsp;</p>
                    <div class="buttons">
                      <button @click="goBack()" class="button is-info"><span><font-awesome-icon icon="chevron-left" /></span>&nbsp; Go Back</button>
                    </div>
                  </div>
                </div>

                <div class="spacer s90"></div>

      				  

      			    <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showDetails}">
      			      <div class="modal-background" @click="close"></div>
      			      <div class="modal-card">
      						    <header class="modal-card-head">
      						      <p class="modal-card-title">Service Details</p>
      						      <button class="delete" aria-label="close" @click.prevent="close"></button>
      						    </header>
      						    <section class="modal-card-body">	
      										<table class="table is-fullwidth">
      									  	<tr>
      									  		<th>Item</th>
      									  		<th>Details</th>
      									  	</tr>
      									  	<tr>
      									  		<td>Service date</td>
      									  		<td>{{currentService.timestamp}}</td>
      									  	</tr>
      									  	<tr>
      									  		<td>Hours at service</td>
      									  		<td>{{currentService.hoursAtService}}</td>
      									  	</tr>
      									  	<tr>
      									  		<td>Serviced by</td>
      									  		<td>{{currentService.postedBy}}</td>
      									  	</tr>
      									  	<tr>
      									  		<td>Service notes</td>
      									  		<td>{{currentService.serviceDetails}}</td>
      									  	</tr>
      									  </table>

      						    </section>
      						    <footer class="modal-card-foot">
      						      <button class="button is-primary" @click.prevent="close">Done</button>
      						    </footer>
      				  	</div>
      			    </div>


      			    <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':editModal}">
      			      <div class="modal-background" @click="close"></div>
      			      <div class="modal-card">
      						    <header class="modal-card-head">
      						      <p class="modal-card-title">{{editingItemName}}</p>
      						      <button class="delete" aria-label="close" @click.prevent="close"></button>
      						    </header>
      						    <section class="modal-card-body">											
      										<input ref="focused" class="input" type="tel" :placeholder="numberWithCommas(editingItemOldValue)" :maxlength="7" v-on:keypress="isNumber($event)" v-model="editingItemValue" />
                          <span class="unit">{{editingItemUnit}}</span>
      						    </section>
      						    <footer class="modal-card-foot">
      						      <button class="button is-primary" @click="saveEditedItem">Update</button>
      						      <button class="button" @click.prevent="close">Cancel</button>
      						    </footer>
      				  	</div>
      			    </div>


                <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':editDropdownModal}">
                  <div class="modal-background" @click="close"></div>
                  <div class="modal-card">
                      <header class="modal-card-head">
                        <p class="modal-card-title">{{editingItemName}}</p>
                        <button class="delete" aria-label="close" @click.prevent="close"></button>
                      </header>
                      <section class="modal-card-body">                     
                          <label class="label-fixed">SERVICE TYPE:</label>
                          <div class="spacer s10"></div>
                          <div class="control">
                            <div class="select">
                              <select v-model="editingItemValue">
                                <option disabled value="">Choose a type</option>
                                <option value="KM">KM</option>                                  
                                <option value="Hours">Hours</option>
                              </select>
                            </div>
                          </div>
                      </section>
                      <footer class="modal-card-foot">
                        <button class="button is-primary" @click="saveEditedModalItem">Update</button>
                        <button class="button" @click.prevent="close">Cancel</button>
                      </footer>
                  </div>
                </div>


                <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':editNameModal}">
                  <div class="modal-background" @click="close"></div>
                  <div class="modal-card">
                      <header class="modal-card-head">
                        <p class="modal-card-title">{{editingItemName}}</p>
                        <button class="delete" aria-label="close" @click.prevent="close"></button>
                      </header>
                      <section class="modal-card-body">                     
                          <input ref="focused" class="input" type="text" :placeholder="editingItemOldValue" maxlength="30" v-model="editingItemValue" />
                          <span class="unit">{{editingItemUnit}}</span>
                      </section>
                      <footer class="modal-card-foot">
                        <button class="button is-primary" @click="saveEditedItem">Update</button>
                        <button class="button" @click.prevent="close">Cancel</button>
                      </footer>
                  </div>
                </div>


      			    <!-- <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showResetFuel}">
      			      <div class="modal-background" @click="close"></div>
      			      <div class="modal-card">
      						    <header class="modal-card-head">
      						      <p class="modal-card-title">Reset Fuel Consumption?</p>
      						      <button class="delete" aria-label="close" @click.prevent="close"></button>
      						    </header>
      						    <section class="modal-card-body">						
      						    		<p>Reset fuel consumption from now, assuming the truck is at full fuel capacity.</p>	
      						    		<p>&nbsp;</p>				
      										<input class="is-checkradio" id="isFull" type="checkbox" v-model="isFull">
      										<label for="isFull">Confirm truck is full of fuel</label>
      						    </section>
      						    <footer class="modal-card-foot">
      						      <button v-if="showResetButton" class="button is-primary" @click="resetFuel">Reset</button>
      						      <button class="button" @click.prevent="close">Cancel</button>
      						    </footer>
      				  	</div>
      			    </div> -->

      			</div>

        </div>

		</div>

</template>


<script>

	import { db } from '../main.js';
	import firebase from 'firebase/app';
	import 'firebase/auth'; 
	import 'firebase/firestore';
	import NProgress from 'nprogress';
	import * as moment from 'moment';

	// const StickyFooter = () => import('../reusables/StickyFooter.vue');
  // const FleetScroller = () => import('../reusables/FleetScroller.vue');
  // const FleetLeftSidebar = () => import('../reusables/FleetLeftSidebar.vue');

	

	export default {

		name: 'ServiceSchedules',

	  components: {
	  	// StickyFooter,
      // FleetScroller,
      // FleetLeftSidebar
	  },

		// props: [
		// 	'truckID',
		// 	'truckName',
		// ],

		mounted: function() {
			firebase.auth().onAuthStateChanged( this.onUserLogin );	
		},

		computed: {
			nextServiceHours() {
				// const lastInterval = ( Math.floor( parseInt(this.lastEngineHours) / parseInt(this.serviceInterval) ) ) * parseInt(this.serviceInterval);
				// return lastInterval + parseInt(this.serviceInterval);
				return parseInt(this.lastServiceHours) + parseInt(this.serviceInterval);
			},
			serviceWarning() {
				return (this.hoursSinceLastService > (0.9 * parseInt(this.serviceInterval))) && (this.hoursSinceLastService <= parseInt(this.serviceInterval));
			},
			serviceOverdue() {
				return this.hoursSinceLastService > parseInt(this.serviceInterval);
			},
			hoursSinceLastService() {
				return parseInt(this.lastEngineHours) - parseInt(this.lastServiceHours);
			},

			// nextServiceHoursGenset() {
			// 	// const lastInterval = ( Math.floor( parseInt(this.lastEngineHoursGenset) / parseInt(this.serviceIntervalGenset) ) ) * parseInt(this.serviceIntervalGenset);
			// 	// return lastInterval + parseInt(this.serviceIntervalGenset);
			// 	return parseInt(this.lastServiceHoursGenset) + parseInt(this.serviceIntervalGenset);
			// },
			// serviceWarningGenset() {
			// 	return (this.hoursSinceLastServiceGenset > (0.9 * parseInt(this.serviceIntervalGenset))) && (this.hoursSinceLastServiceGenset <= parseInt(this.serviceIntervalGenset));
			// },
			// serviceOverdueGenset() {
			// 	return this.hoursSinceLastServiceGenset > parseInt(this.serviceIntervalGenset);
			// },
			// hoursSinceLastServiceGenset() {
			// 	return parseInt(this.lastEngineHoursGenset) - parseInt(this.lastServiceHoursGenset);
			// },

      currentTruckID() {
        return this.$store.getters.getCurrentTruckID;
      },
      currentTruckName() {
        return this.$store.getters.getCurrentTruckName;
      },
      currentTruckUVI() {
        return this.$store.getters.getCurrentTruckUVI;
      },

		},

		data: function() {
			return {
				firebaseid: '',
				firebaseemail: '',
				serviceHistoryMain: [],
				serviceHistoryGenset: [],
				currentService: '',
				showDetails: false,

				// serviceInterval: '',
				// lastEngineHours: '',
				// lastServiceHours: '',
				// lastServiceDate: '',

				// serviceIntervalGenset: '',
				// lastEngineHoursGenset: '',
				// lastServiceHoursGenset: '',
				// lastServiceDateGenset: '',

        serviceAname: 'Service A',
        serviceAunit: '',
        serviceAinterval: 0,
        serviceAlastServiceValue: 0,
        serviceAlatestReading: 0,
        serviceAnextServiceValue: 0,
        serviceAlastServiceDate: '',

        serviceBname: 'Service B',
        serviceBunit: '',
        serviceBinterval: 0,
        serviceBlastServiceValue: 0,
        serviceBlatestReading: 0,
        serviceBnextServiceValue: 0,
        serviceBlastServiceDate: '',

        serviceCname: 'Service C',
        serviceCunit: '',
        serviceCinterval: 0,
        serviceClastServiceValue: 0,
        serviceClatestReading: 0,
        serviceCnextServiceValue: 0,
        serviceClastServiceDate: '',

				editModal: false,
        editDropdownModal: false,
        editNameModal: false,
				editingItem: '',
				editingItemOldValue: '',
				editingItemValue: '',
				editingItemName: '',
        editingItemUnit: '',

				//startingHours: 0,
				//runningFuelTotal: 0,

				//showResetFuel: false,
				//isFull: false,
				profileTab: 'serviceA',
			}
		},

		methods: {

			onUserLogin: function( user ) {
      	if (user) {      		
	      	this.firebaseemail = user.email;
	      	//this.firebasedisplayname = user.displayName;
	      	this.firebaseid = user.uid;
	      	this.loadServiceInfo();
	      	this.loadServiceHistory();
	      }
      },

      goBack: function() {
				this.$router.go(-1);
			},

			isNumber: function($evt) {
	      $evt = ($evt) ? $evt : window.event;
	      var charCode = ($evt.which) ? $evt.which : $evt.keyCode;
	      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
	        $evt.preventDefault();
	      } else {
	        return true;
	      }
	    },

      numberWithCommas(x) {
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },

      formatDate : function (sentDate,format) {
          let fixDate = (sentDate ? sentDate.toDate() : new Date());
          return moment(fixDate).format(format);
      },

      loadServiceInfo: function() {
      	var self = this;

				db.collection('trucks').doc(this.currentTruckID).onSnapshot(function(doc) {
					// serviceA
          self.serviceAname = doc.data().serviceAname ? doc.data().serviceAname : 'Service A';
					self.serviceAunit = doc.data().serviceAunit ? doc.data().serviceAunit : 'KM';
          self.serviceAinterval = doc.data().serviceAinterval ? doc.data().serviceAinterval : 0;
          self.serviceAlastServiceValue = doc.data().serviceAlastServiceValue ? doc.data().serviceAlastServiceValue : 0;
          self.serviceAlatestReading = doc.data().serviceAlatestReading ? doc.data().serviceAlatestReading : 0;
          self.serviceAnextServiceValue = doc.data().serviceAnextServiceValue ? doc.data().serviceAnextServiceValue : 0;
					self.serviceAlastServiceDate = doc.data().serviceAlastServiceDate ? self.formatDate(doc.data().serviceAlastServiceDate,'D MMMM YYYY') : '';
          // serviceB
          self.serviceBname = doc.data().serviceBname ? doc.data().serviceBname : 'Service B';
          self.serviceBunit = doc.data().serviceBunit ? doc.data().serviceBunit : 'KM';
          self.serviceBinterval = doc.data().serviceBinterval ? doc.data().serviceBinterval : 0;
          self.serviceBlastServiceValue = doc.data().serviceBlastServiceValue ? doc.data().serviceBlastServiceValue : 0;
          self.serviceBlatestReading = doc.data().serviceBlatestReading ? doc.data().serviceBlatestReading : 0;
          self.serviceBnextServiceValue = doc.data().serviceBnextServiceValue ? doc.data().serviceBnextServiceValue : 0;
          self.serviceBlastServiceDate = doc.data().serviceBlastServiceDate ? self.formatDate(doc.data().serviceBlastServiceDate,'D MMMM YYYY') : '';
          // serviceC
          self.serviceCname = doc.data().serviceCname ? doc.data().serviceCname : 'Service C';
          self.serviceCunit = doc.data().serviceCunit ? doc.data().serviceCunit : 'KM';
          self.serviceCinterval = doc.data().serviceCinterval ? doc.data().serviceCinterval : 0;
          self.serviceClastServiceValue = doc.data().serviceClastServiceValue ? doc.data().serviceClastServiceValue : 0;
          self.serviceClatestReading = doc.data().serviceClatestReading ? doc.data().serviceClatestReading : 0;
          self.serviceCnextServiceValue = doc.data().serviceCnextServiceValue ? doc.data().serviceCnextServiceValue : 0;
          self.serviceClastServiceDate = doc.data().serviceClastServiceDate ? self.formatDate(doc.data().serviceClastServiceDate,'D MMMM YYYY') : '';
				});
      },

			loadServiceHistory: function() {
				var self = this;
				db.collection('trucks').doc(this.currentTruckID).collection('serviceHistoryMain').orderBy('timestamp','desc').onSnapshot(function(querySnapshot) {
					querySnapshot.forEach(function(doc) {
						self.serviceHistoryMain.push({
							id: doc.id,
							hoursAtService: doc.data().hoursAtService,
							postedBy: doc.data().postedBy,
							serviceDetails: doc.data().serviceDetails,
							timestamp: self.formatDate(doc.data().timestamp,'dddd D MMMM (h:mm a)')
						});
					});
				});
				db.collection('trucks').doc(this.currentTruckID).collection('serviceHistoryGenset').orderBy('timestamp','desc').onSnapshot(function(querySnapshot) {
					querySnapshot.forEach(function(doc) {
						self.serviceHistoryGenset.push({
							id: doc.id,
							hoursAtService: doc.data().hoursAtService,
							postedBy: doc.data().postedBy,
							serviceDetails: doc.data().serviceDetails,
							timestamp: self.formatDate(doc.data().timestamp,'dddd D MMMM (h:mm a)')
						});
					});
				});
			},

			// updateNextService: function() {
			// 	console.log('Updating next service hours...');
			// 	const lastInterval = ( Math.floor( parseInt(this.lastEngineHours) / parseInt(this.serviceInterval) ) ) * parseInt(this.serviceInterval);
			// 	this.nextServiceHours = lastInterval + parseInt(this.serviceInterval);
			// },

			close: function() {
				this.showDetails = false;
				this.editModal = false;
        this.editDropdownModal = false;
        this.editingItemValue = '';
        this.editingItem = '';
        this.editingItemOldValue = '';
        this.editingItemUnit = '';
        this.editNameModal = false;
				//this.showResetFuel = false;
			},

			showServiceDetails: function(service) {
				this.currentService = service;
				this.showDetails = true;
			},

			editItem: function(itemName,itemSlug,itemValue, itemUnit) {
				this.editingItemName = itemName;
				this.editingItem = itemSlug;
				this.editingItemOldValue = itemValue;
        this.editingItemUnit = itemUnit;
				this.editModal = true;
				this.$refs.focused.focus();
			},

      editServiceUnit(itemName,itemSlug,itemValue) {
        // console.log(itemName,itemSlug,itemValue);
        this.editingItemName = itemName;
        this.editingItem = itemSlug;
        this.editingItemValue = itemValue;
        this.editDropdownModal = true;
      },

      editItemName: function(itemSlug,itemValue, itemUnit) {
        this.editingItemName = 'Change Service Name';
        this.editingItem = itemSlug;
        this.editingItemOldValue = itemValue;
        this.editingItemUnit = itemUnit;
        this.editNameModal = true;
        this.$refs.focused.focus();
      },

			saveEditedItem: function() {
				var self = this;
				//console.log(this.editingItem,this.editingItemValue);
				NProgress.start();
				var newValue = {};
				newValue[self.editingItem] = self.editingItemValue;
				//console.log(newValue);
				db.collection('trucks').doc(self.currentTruckID).update(newValue)
				.then(function() {
					self.close()
					NProgress.done();
				}).catch(function(err) {
					self.close()
					console.log(err);
				  NProgress.done();
				});
			},

      saveEditedModalItem() {
        let self = this;
        //console.log(this.editingItem,this.editingItemValue);
        NProgress.start();
        let newValue = {};
        newValue[self.editingItem] = self.editingItemValue;
        //console.log(newValue);
        db.collection('trucks').doc(self.currentTruckID).update(newValue)
        .then(function() {
          self.close();
          NProgress.done();
        }).catch(function(err) {
          self.close();
          console.log(err);
          NProgress.done();
        });
      },

			// resetFuel: function() {
			// 	var self = this;  	
			// 	NProgress.start();
			// 	db.collection('trucks').doc(self.truckID).update({
			// 		startingHours: self.lastEngineHours,
			// 		runningFuelTotal: 0
			// 	}).then(function() {
			// 		self.showResetFuel = false;
			// 		self.isFull = false;
			// 		NProgress.done();
			// 	});
			// },


		}

	}

</script>



<style lang="scss" scoped>
	
	h3 {
		margin: 1rem 0;
		text-transform: uppercase;
	}
	table {
		margin-bottom: 3rem !important;
		table-layout: fixed;
    tr {
      td {
        line-height: 2rem;
        .button {
          margin-left: 10px;
        }
      }
    }
	}
  .modal-card-body {
    input[type="tel"] {
      display: inline-block;
      width: 120px;
    }
    .unit {
      line-height: 40px;
      display: inline-block;
      margin-left: 10px;
    }
  }
  .tabs {
    li {
      max-width: 33%;
      overflow: hidden;
      a {
        justify-content: left;
        text-align: left;
      }
    }
  }

</style>
<template>

	<div class="site-wrapper no-header">

		
	
		<div class="container">

      <div class="buttons">
        <!-- <button class="button is-skip" @click="nextStep()">Skip to Part 9 &nbsp;<span><font-awesome-icon icon="chevron-right" /></span></button> -->
        <button class="button is-info" @click="goBack()"><span><font-awesome-icon icon="chevron-left" /></span> &nbsp;Back &nbsp;</button>
      </div>

        <div class="before-icon">
          <font-awesome-icon icon="calendar-check" />
        </div>
        <h4 class="icon">{{archived ? 'View' : editing ? 'Edit' : 'New'}} Truck's Log</h4> 

			  <div class="">
	          
	          <div class="form-group">      
              <textarea :disabled="archived" v-model="newLog.details" class="textarea" placeholder="Log Details (required)" rows="5"></textarea>
            </div>

            <input :disabled="archived" class="is-checkradio" id="isPriority" type="checkbox" v-model="newLog.priority">
            <label for="isPriority">This is a priority</label>
            <p>&nbsp;</p>
	          
            <div class="buttons">
           		<button :disabled="archived" v-if="newLog.details != ''" class="button is-primary" @click="saveNewLog()">Save Log</button>
              <button class="button" v-else>Write the trucks log</button>
              <button v-if="editing && !archived" class="button is-warning" @click="archiveLog()">Archive Log</button>
              <button v-if="editing && archived" class="button is-success" @click="restoreLog()">Restore Log</button>
              <button v-if="editing" class="button is-danger" @click="deleteLog()">Delete Permanently</button>
            </div>


			  </div>
			  

		</div>


	</div>
	
			

</template>

<script>	

	import { db } from '../main.js';
	import firebase from 'firebase/app';
	import 'firebase/auth'; 
	import 'firebase/firestore';
	import NProgress from 'nprogress';
	// import * as moment from 'moment'

	export default {

		name: 'AddEditTrucksLog',
    props: {
      sentLog: Object,
      editing: Boolean,
      archived: Boolean
    },
		mounted: function() {
			firebase.auth().onAuthStateChanged( this.onUserLogin );		
      this.newLog.id = this.sentLog.id ? this.sentLog.id : ''; 
      this.newLog.details = this.sentLog.details ? this.sentLog.details : ''; 
      this.newLog.priority = this.sentLog.priority ? this.sentLog.priority : false; 
		},
		computed: {
      currentTruckID() {
        return this.$store.getters.getCurrentTruckID;
      },
      currentTruckName() {
        return this.$store.getters.getCurrentTruckName;
      },
      userData() {
        return this.$store.getters.getSubscriptionInfo;
      },
		},
		data: function() {
			return {
				msg: 'Add Alert',
				firebaseid: '',
				newLog: {
          id: '',
          details: '',
          priority: false
        },
			}
		},
		methods: {
			onUserLogin: function( user ) {
      	if (user) {      		
	      	this.firebaseid = user.uid;
	      }
	    },

			saveNewLog: function() {
      	var self = this;
        NProgress.start();

        if (self.editing) {

            db.collection('trucks').doc(self.currentTruckID).collection('trucksLog').doc(self.newLog.id).update({
              logDetails: self.newLog.details ? self.newLog.details : '',
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
              logTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
              postedBy: self.userData.username ? self.userData.username : 'not recorded',
              priority: self.newLog.priority,
            })
            .then(() => {
              var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
              addTruckRevision({
                userName: self.userData.username,
                userID: self.firebaseid,
                truckName: self.currentTruckName,
                truckID: self.currentTruckID,
                revisionDetails: 'Trucks log updated',
              });
              NProgress.done();
              self.goBack();
            })
            .catch(error => {
                console.error("Error updating truck log: ", error);
                NProgress.done();
                self.goBack();
            });

        } else {

            db.collection('trucks').doc(self.currentTruckID).collection('trucksLog').add({
                logDetails: self.newLog.details ? self.newLog.details : '',
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                logTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
                postedBy: self.userData.username ? self.userData.username : 'not recorded',
                priority: self.newLog.priority,
                archived: false,
                location: 'Not set',
                truckID: self.currentTruckID ? self.currentTruckID : '',
                truckName: self.currentTruckName ? self.currentTruckName : ''
            })
            .then(() => {
                var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
                addTruckRevision({
                  userName: self.userData.username,
                  userID: self.firebaseid,
                  truckName: self.currentTruckName,
                  truckID: self.currentTruckID,
                  revisionDetails: 'New truck log added',
                });
                NProgress.done();
                self.goBack();
            })
            .catch(error => {
                console.error("Error adding log: ", error);
                NProgress.done();
                self.goBack();
            });

        }

      	

      },

      archiveLog() {
        let self = this;    
        let message = {
          title: 'Archive this log?',
          body: ''
        };
        let options = {
          okText: 'Yes, Archive',
          backdropClose: true,
          cancelText: 'Cancel',
        };
        this.$dialog
          .confirm(message, options)
          .then(function() {

              NProgress.start();  
            
              db.collection('trucks').doc(self.currentTruckID).collection('trucksLog').doc(self.newLog.id).update({
                archived: true
              })
              .then(() => {
                  var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
                  addTruckRevision({
                    userName: self.userData.username,
                    userID: self.firebaseid,
                    truckName: self.currentTruckName,
                    truckID: self.currentTruckID,
                    revisionDetails: 'Trucks log archived',
                  });
                  NProgress.done();
                  self.goBack();
              })
              .catch(error => {
                  console.error("Error archiving log: ", error);
                  NProgress.done();
                  self.goBack();
              });
              
          })
          .catch(function() {
            console.log('Clicked on cancel'); 
            NProgress.done();
          });
      },

      restoreLog() {
        let self = this;    
        let message = {
          title: 'Restore this log?',
          body: ''
        };
        let options = {
          okText: 'Yes, Restore',
          backdropClose: true,
          cancelText: 'Cancel',
        };
        this.$dialog
          .confirm(message, options)
          .then(function() {

              NProgress.start();  
            
              db.collection('trucks').doc(self.currentTruckID).collection('trucksLog').doc(self.newLog.id).update({
                archived: false
              })
              .then(() => {
                  var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
                  addTruckRevision({
                    userName: self.userData.username,
                    userID: self.firebaseid,
                    truckName: self.currentTruckName,
                    truckID: self.currentTruckID,
                    revisionDetails: 'Trucks log restored',
                  });
                  NProgress.done();
                  self.goBack();
              })
              .catch(error => {
                  console.error("Error restoring log: ", error);
                  NProgress.done();
                  self.goBack();
              });
              
          })
          .catch(function() {
            console.log('Clicked on cancel'); 
            NProgress.done();
          });
      },

      deleteLog() {
        let self = this;    
        let message = {
          title: 'Delete this log?',
          body: 'This can\'t be undone.'
        };
        let options = {
          okText: 'Yes, Delete',
          backdropClose: true,
          cancelText: 'Cancel',
        };
        this.$dialog
          .confirm(message, options)
          .then(function() {

              NProgress.start();  
            
              db.collection('trucks').doc(self.currentTruckID).collection('trucksLog').doc(self.newLog.id).delete()
              .then(() => {
                  var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
                  addTruckRevision({
                    userName: self.userData.username,
                    userID: self.firebaseid,
                    truckName: self.currentTruckName,
                    truckID: self.currentTruckID,
                    revisionDetails: 'Trucks log deleted',
                  });
                  NProgress.done();
                  self.goBack();
              })
              .catch(error => {
                  console.error("Error deleting log: ", error);
                  NProgress.done();
                  self.goBack();
              });
              
          })
          .catch(function() {
            console.log('Clicked on cancel'); 
            NProgress.done();
          });
      },


      goBack: function() {
        // console.log('Router',this.$router);
        this.$router.go(-1);
      },


		},

	}

</script>



<style lang="scss" scoped>

	.message {
		.message-body {
			background: #fff;
		}
	}
	textarea {
		min-height: 100px !important;
		box-shadow: none;
    padding: 0.6rem 1rem;
    &.notes {
    	margin-top: 1rem;
    }
	}


</style>
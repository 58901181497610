<template>

  <div>

    <div class="fleet-wrapper">

      <div class="main-fleet-content">

        <ul class="scroller">
          <li>
            <router-link to="my-dashboard">Dashboard</router-link>
          </li>
          <li>
            <router-link to="truck-edit-sms">Truck Details</router-link>
          </li>
          <li class="is-active">
            <a aria-current="page">Activity Logs</a>
          </li>
        </ul>

        <div v-if="currentTruckID">
            
            <br>
            <div class="before-icon"><font-awesome-icon icon="list" /></div>
            <h4 class="icon">All logged activity on <span>{{ currentTruckName }}</span></h4>
            <div class="clear"></div>

            <div v-if="truckRevisions.length">
              <ul class="revisions">
                <li v-for="(item, index) in truckRevisions" :key="index">{{item.revision}} by {{item.userName}} on {{formatDate(item.timestamp,'D MMMM (h:mm a)')}}</li>
              </ul>
            </div>
            <div v-else>
              <p>No activity at the moment.</p>
            </div>

          
        </div>
        <div v-else>
          <p>No truck detected.</p>
          <p>&nbsp;</p>
          <router-link to="truck-edit-sms">
            <span class="button is-info">Back to SMS</span>
          </router-link>
        </div>

        <div class="spacer s90"></div>

        

      </div>
    </div>

  </div>

</template>

<script>
import { db } from '../main.js';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
//import 'firebase/functions';
// import NProgress from 'nprogress';
// const FleetScroller = () => import('../reusables/FleetScroller.vue');
import * as moment from 'moment';
// const FleetLeftSidebar = () => import('../reusables/FleetLeftSidebar.vue');


export default {

  mounted: function() {
    firebase.auth().onAuthStateChanged(this.onUserLogin);
  },
  components: {
    // FleetScroller,
    // FleetLeftSidebar
  },
  computed: {
    isUserAdmin() {
      return this.$store.getters.getIsUserAdmin;
    },
    currentTruckID() {
      return this.$store.getters.getCurrentTruckID;
    },
    currentTruckName() {
      return this.$store.getters.getCurrentTruckName;
    },
    currentTruckUVI() {
      return this.$store.getters.getCurrentTruckUVI;
    },
    // currentFleet() {
    //   return this.$store.getters.getCurrentFleet;
    // },
  },
  data: function() {
    return {
      firebaseid: '',
      truckRevisions: [],

    }
  },
  methods: {

    onUserLogin: function(user) {
      if (user) {
        this.firebaseid = user.uid;
        this.loadRevisions();
      }
    },

    formatDate : function (sentDate,format) {
      let fixDate = (sentDate ? sentDate.toDate() : new Date());
      return moment(fixDate).format(format);
    },

    loadRevisions: function() {
      let self = this;
      db.collection('trucks').doc(this.currentTruckID).collection('truckRevisions').orderBy('timestamp','desc').limit(200).onSnapshot(function(querySnapshot) {
        self.truckRevisions = [];
        querySnapshot.forEach((doc) => {
          self.truckRevisions.push(doc.data());
        });
      });
    },



  }

}
</script>
<style lang="scss" scoped>

  ul.revisions {
    list-style: disc inside;
    margin-left: 8px;
    text-align: left;
    li {
      text-indent: -23px;
      margin-left: 20px;
      margin-bottom: 10px;
    }
  }

</style>
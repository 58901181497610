<template>

	<div>

		<div class="fleet-wrapper">
      
      <fleet-left-sidebar></fleet-left-sidebar>

			<div class="main-fleet-content">

        <fleet-scroller tab="Startups" backLink="fleet-edit-sms" backTitle="Edit SMS"></fleet-scroller>

				<div v-if="currentTruckID">

					<div class="card">
						<br>
            <div class="before-icon"><font-awesome-icon icon="check-circle" /></div><h4 class="icon">Startup Checklist on <span>{{currentTruckName}}</span></h4>  
            <div class="clear"></div>
						<div v-if="truckItems.length" class="card-content">
							<div v-for="(item, index) in sorted(truckItems)" :key="index">
								<div class="notification">
									<div class="item-title">{{item.title}}</div>
									<div class="item-details">
										<div v-for="line in item.details.split('\n')" :key="line">{{line}}<br></div>
									</div>
									<div class="buttons">
										<!-- <span class="button is-success" @click="launchEditItem(item.id,item.title,item.details)">Edit</span>
										<span class="button is-danger" @click="deleteItem(item.id)">Remove</span> -->
                    <span class="button is-info" @click="launchItemWindow(item.id,item.title,item.details,'Read')">Read</span>
                    <span class="button is-success" @click="launchItemWindow(item.id,item.title,item.details,'Edit')">Edit</span>
                    <span class="button is-danger" @click="deleteItem(item.id)">Delete</span>
									</div>
								</div>	
							</div>
							<!-- <span class="simple-link right" @click="sorted(truckItems)">Sort</span> -->
							<span class="simple-link right" @click="enableSortModal">Change order</span>
						</div>
						<div v-else><p>No truck specific items.</p></div>
					</div>

					<div class="button-wrapper full">
						<!-- <button v-on:click="launchAddNewItem" class="button is-primary">Write a truck Item</button>	
						<button v-on:click="launchAddHelperItems" class="button is-primary">Add helper Items</button> -->
            <button @click="launchItemWindow('','','','Write New')" class="button is-primary">Write new item</button>                     
            <button v-on:click="launchAddHelperItems()" class="button is-primary">Add helper items</button> 
					</div>

					<hr>

				</div>

				<div v-else>

					<p>No truck detected.</p><p>&nbsp;</p>
					<router-link to="fleet-edit-sms">
              <span class="button is-info">Back to SMS</span>
            </router-link>

				</div>


				<div>

					<!-- <div v-if="smsFleetItems.length" class="card">
						<h3 class="heading">COMPANY Items</h3>
						<div class="card-content">
							<div v-for="(item, index) in smsFleetItems" :key="index">
								<div class="notification">
									<div class="item-title">{{item.title}}</div>
									<div class="item-details">
										<div v-for="line in item.details.split('\n')" :key="line">{{line}}<br></div>
									</div>
                  <div class="buttons">
                    <span class="button is-info" @click="launchItemWindow(item.id,item.title,item.details,'Read')">Read</span>
                    <span class="button is-danger" @click="deleteFleetItem(item.id)">Delete</span>
                  </div>
								</div>	
							</div>
						</div>
					</div> -->

					<!-- <div class="button-wrapper full">
						<button v-on:click="launchAddFleetItems" class="button is-primary">Edit fleet Items</button>	
					</div> -->

					<!-- <hr> -->

				</div>

				<div class="button-wrapper full">
					<router-link to="fleet-edit-sms">
            <span class="button is-info">Back to SMS</span>
          </router-link>
		    </div>

          <div class="spacer s50"></div>




		  <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':enableSort}">
		  	<div class="modal-background" @click="close"></div>
		  	<div class="modal-card">
		  		<header class="modal-card-head">
		  			<p class="modal-card-title">Drag to change order of items</p>
		  			<button class="delete" aria-label="close" @click.prevent="close"></button>
		  		</header>
		  		<section class="modal-card-body">	

		  			<div v-if="truckItems.length">

		  				<draggable v-model="truckItems" class="sorting-list" group="people" @start="drag=true" @end="drag=false">
		  					<div v-for="(item, index) in truckItems" :key="item.id">{{index + 1}} - {{item.title}}  <span>&#8597;</span></div>
		  				</draggable>

		  			</div>

		  		</section>
		  		<footer class="modal-card-foot">
		  			<button class="button is-primary" @click="saveNewOrder">Update</button>
		  			<button class="button cancel" @click.prevent="close">Cancel</button>
		  		</footer>
		  	</div>
		  </div>



		  <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':isActiveAddFleetItems}">
		  	<div class="modal-background" @click="close"></div>
		  	<div class="modal-card">
		  		<header class="modal-card-head">
		  			<p class="modal-card-title">Select fleet Items:</p>
		  			<button class="delete" aria-label="close" @click.prevent="close"></button>
		  		</header>
		  		<section class="modal-card-body">	

		  			<div v-if="fleetItems.length" class="checkbox-wrapper">

		  				<div class="field" v-for="(item, index) in fleetItems" :key="index">
		  					<input class="is-checkradio" type="checkbox" :id="item.id" :value="item" v-model="smsFleetItems">
		  					<label :for="item.id">{{item.title}}</label>
		  				</div>

		  			</div>

		  			<div v-else>
		  				<p>No Fleet Items found.</p>
		  			</div>

		  		</section>
		  		<footer class="modal-card-foot">
		  			<button class="button is-primary" @click="saveFleetItems">Update</button>
		  			<button class="button cancel" @click.prevent="close">Cancel</button>
		  		</footer>
		  	</div>
		  </div>




		  <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':isActiveEditItemModal}">
		  	<div class="modal-background" @click="close"></div>
		  	<div class="modal-card">
		  			<header class="modal-card-head">
		  				<p class="modal-card-title">Edit Item:</p>
		  				<button class="delete" aria-label="close" @click.prevent="close"></button>
		  			</header>
		  			<section class="modal-card-body">	
		  				<div class="form-group">      
		  					<input class="input" type="text" v-model="editedItem.title" required>
		  					<span class="highlight"></span>
		  					<span class="bar"></span>
		  					<label>Item Title:</label>
		  				</div>
		  				<div class="form-group">      
		  					<textarea rows="3" placeholder="Item Details:" class="input" v-model="editedItem.details" required></textarea>
		  				</div>
		  			</section>
		  			<footer class="modal-card-foot">
		  				<button class="button is-primary" @click="saveEditItem">Update item</button>
		  				<button class="button cancel" @click.prevent="close">Cancel</button>
		  			</footer>
		  	</div>
		  </div>



		  <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':isActiveAddHelperItems}">
		  	<div class="modal-background" @click="close"></div>
		  	<div class="modal-card">
		  		<header class="modal-card-head">
		  			<p class="modal-card-title">Suggested Items:</p>
		  			<button class="delete" aria-label="close" @click.prevent="close"></button>
		  		</header>
		  		<section class="modal-card-body">	

		  			<div class="checkbox-wrapper">
		  				<div class="field" v-for="(item, index) in helperItems" :key="index">
		  					<input class="is-checkradio" type="checkbox" :id="item.id" :value="item" v-model="selectedItems">
		  					<label :for="item.id">{{item.title}}</label>
		  				</div>
		  			</div>

		  		</section>
		  		<footer class="modal-card-foot">
		  			<button class="button is-primary" @click="saveHelperItems">Add to my Items</button>
		  			<button class="button cancel" @click.prevent="close">Cancel</button>
		  		</footer>
		  	</div>
		  </div>




		  <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':isActiveNewItemModal}">
		  	<div class="modal-background" @click="close"></div>
		  	<div class="modal-card">
		  		<!-- <form id="truckForm" @submit.prevent="saveNewItem"> -->	
		  			<header class="modal-card-head">
		  				<p class="modal-card-title">Write a new item:</p>
		  				<button class="delete" aria-label="close" @click.prevent="close"></button>
		  			</header>
		  			<section class="modal-card-body">				
		  				<div class="form-group">      
		  					<input class="input" type="text" v-model="newitem.title" required>
		  					<span class="highlight"></span>
		  					<span class="bar"></span>
		  					<label>Item Title:</label>
		  				</div>
		  				<div class="form-group">      
		  					<textarea rows="3" placeholder="Details (optional)" class="input" v-model="newitem.details"></textarea>
		  				</div>
		  			</section>
		  			<footer class="modal-card-foot">
		  				<!-- <button class="button is-primary" @click="saveNewItem" :disabled="showAddItemButton">Save new item</button> -->
		  				<button class="button is-primary" @click="saveNewItem">Save new item</button>
		  				<button class="button cancel" @click.prevent="close">Cancel</button>
		  			</footer>
		  			<!-- </form> -->
		  		</div>
		  	</div>




        <edit-item-window :item="selectedItem" v-if="showItemWindow == true" v-on:sendItem="returnedItem"></edit-item-window>









		  </div>

		</div>

	</div>

</template>






<script>

import { db } from '../main.js';
import firebase from 'firebase/app';
import 'firebase/auth'; 
import 'firebase/firestore';
	// import 'firebase/functions';
	import NProgress from 'nprogress';

	import draggable from 'vuedraggable';
  import EditItemWindow from '../reusables/EditItemWindow.vue';
  const FleetScroller = () => import('../reusables/FleetScroller.vue');
  const FleetLeftSidebar = () => import('../reusables/FleetLeftSidebar.vue');

	export default {

		name: 'StartupChecklists',
		components: {
			draggable,
      EditItemWindow,
      FleetScroller,
      FleetLeftSidebar
		},
		mounted: function() {
			firebase.auth().onAuthStateChanged( this.onUserLogin );		
		},
		computed: {
			isUserAdmin() {
				return this.$store.getters.getIsUserAdmin;
			},
			currentTruckID() {
				return this.$store.getters.getCurrentTruckID;
			},
			currentTruckName() {
				return this.$store.getters.getCurrentTruckName;
			},
			currentTruckUVI() {
				return this.$store.getters.getCurrentTruckUVI;
			},
			showAddItemButton() {
				return this.newitem.title == '' || this.newitem.title == undefined;
			},
			selectedFleetItemIDs() {
				var tempSelectedIDs = this.smsFleetItems.map(el => {
					return el.id;
				});
				return tempSelectedIDs;
			},
			unselectedFleetItems() {
				var self = this;
				var tempUnselected = this.fleetItems.filter(el => {
					return !self.selectedFleetItemIDs.includes(el.id);
				});
				return tempUnselected;
			},
			userRole() {
				return this.$store.getters.getSubscriptionInfo.userrole;
			},
			isTruckOwner() {
				return this.firebaseid === this.truckOwnerID;
			},
			userData() {
				return this.$store.getters.getSubscriptionInfo;
			},
      currentFleet() {
        return this.$store.getters.getCurrentFleet;
      },
		},

		data: function() {
			return {
				msg: 'Startup Checklists',
				firebaseid: '',
				firebaseemail: '',
				firebaserole: '',
				firebasedisplayname: '',

				fleetItems: [],
				selectedItems: [],
				isActiveNewItemModal: false,
				isActiveAddHelperItems: false,
				isActiveAddFleetItems: false,
				isActiveEditItemModal: false,
				truckItems: [],
				editedItem: {},

				helperItems: [],
				newitem: {},
				smsFleetItems: [],
				truckOwnerID: null,

				enableSort: false,

        selectedItem: {},
        showItemWindow: false,
			}
		},
		methods: {

			onUserLogin: function( user ) {
				if (user) {      		
					this.firebaseemail = user.email;
          this.firebasedisplayname = this.userData.username;
	      	this.firebaseid = user.uid;
	      	this.loadFleetItems();
	      	this.loadSmsFleetItems();
	      	this.loadHelperItems();
	      	this.loadSavedItems();
	      }
	  },

      launchItemWindow: function(itemID,itemTitle,itemDetails,action) {
        this.showItemWindow = true;
        this.selectedItem = {
          id: itemID,
          title: itemTitle,
          details: itemDetails,
          heading: 'Item',
          action: action
        };
      },
      returnedItem: function(returnItem) {
        
        this.showItemWindow = false;
        this.selectedItem = {};

        if (returnItem.edited) {
          NProgress.start();
          let self = this;
          if (returnItem.isNewItem) {
            db.collection('trucks').doc(self.currentTruckID).collection('smsStartupItems').add({
              title: returnItem.title,
              details: returnItem.details,
            })
            .then(() => {
              NProgress.done();
              var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
              addTruckRevision({
                userName: self.firebasedisplayname,
                userID: self.firebaseid,
                truckName: self.currentTruckName,
                truckID: self.currentTruckID,
                revisionDetails: 'New startup checklist item added',
              });
            })
            .catch(error => {
              console.error("Error adding new item: ", error);
              NProgress.done();
            });
          } else {
            db.collection('trucks').doc(self.currentTruckID).collection('smsStartupItems').doc(returnItem.id).update({
              title: returnItem.title,
              details: returnItem.details,
            })
            .then(() => {
              NProgress.done();
              var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
              addTruckRevision({
                userName: self.firebasedisplayname,
                userID: self.firebaseid,
                truckName: self.currentTruckName,
                truckID: self.currentTruckID,
                revisionDetails: 'Startup checklist item updated',
              });
            })
            .catch(error => {
              console.error("Error updating item: ", error);
              NProgress.done();
            });
          }
        } else {
          NProgress.done();
        }

      },



	  enableSortModal: function() {
	  	this.truckItems = this.sorted(this.truckItems);
	  	this.enableSort = true;
	  },

	  sorted: function(arr) {
		  // Set slice() to avoid to generate an infinite loop!
		  // console.log('Arr:',arr);
		  return arr.slice().sort(function(a, b) {
		  	return a.index - b.index;
		  });
		},

		saveNewOrder: function() {
			var self = this;
			NProgress.start();
			this.truckItems.forEach(function(item, index){
				db.collection("trucks").doc(self.currentTruckID).collection("smsStartupItems").doc(item.id).update({
					index: index + 1
				})
				.then(() => {
						// self.truckItems = self.sorted(self.truckItems);
						self.loadSavedItems();
					})
				.then(() => {
					self.enableSort = false;
					NProgress.done();
				})
				.catch(error => {
					console.error("Error adding alert: ", error);
					NProgress.done();
				});
			});		
		},

		loadSmsFleetItems: function() {
			var self = this;

			db.collection("trucks").doc(this.currentTruckID).collection("smsFleetStartupItems").onSnapshot(function(querySnapshot) {
				self.smsFleetItems = [];
				querySnapshot.forEach(function(doc) {
					self.smsFleetItems.push({
						id: doc.id,
						title: doc.data().title,
						details: doc.data().details
					});
				});
			});

		},

		launchAddFleetItems: function() {
			this.isActiveAddFleetItems = true;
		},

		launchAddNewItem: function() {
			this.isActiveNewItemModal = true;
		},

		launchAddHelperItems: function() {
			this.isActiveAddHelperItems = true;
		},

		close: function() {
			this.isActiveAddFleetItems = false;
			this.selectedItems = [];
			this.isActiveEditItemModal = false;
			this.editingitem = {};
			this.isActiveNewItemModal = false;
			this.isActiveAddHelperItems = false;
			this.newitem.title = '';
			this.newitem.details = '';
			this.enableSort = false;
		},

		loadFleetItems: function() {
			var self = this;
			db.collection('fleets').doc(this.currentFleet.id).collection('fleetStartupItems').onSnapshot(function(querySnapshot) {
				self.fleetItems = [];
				querySnapshot.forEach(function(doc) {
					self.fleetItems.push({
						id: doc.id,
						title: doc.data().title,
						details: doc.data().details,
            index: doc.data().index != null && doc.data().index != undefined ? doc.data().index : 999,
					});
				});
			});
		},

		loadHelperItems: function() {
			var self = this;

			db.collection("helperPrestarts").get().then(function(querySnapshot) {
				self.helperItems = [];
				querySnapshot.forEach(function(doc) {
					self.helperItems.push({
						id: doc.id,
						title: doc.data().title,
						details: doc.data().details,
            index: doc.data().index != null && doc.data().index != undefined ? doc.data().index : 999,
					});
				});
			});
		},

		loadSavedItems: function() {
			var self = this;
			db.collection("trucks").doc(this.currentTruckID).onSnapshot(function(doc) {
				self.truckOwnerID = doc.data().truckOwnerID;
			});
			db.collection("trucks").doc(this.currentTruckID).collection("smsStartupItems").onSnapshot(function(querySnapshot) {
				self.truckItems = [];
				querySnapshot.forEach(function(doc) {
					self.truckItems.push({
						id: doc.id,
						title: doc.data().title,
						details: doc.data().details ? doc.data().details : '',
						index: doc.data().index != null && doc.data().index != undefined ? doc.data().index : 999,
					});
				});
			});
		},

		saveFleetItems: function() {
			NProgress.start();
			var self = this;

			if (self.unselectedFleetItems.length) {
				self.unselectedFleetItems.forEach((item) => {
					db.collection('trucks').doc(self.currentTruckID).collection('smsFleetStartupItems').doc(item.id).delete();
				});
			}

			if (self.smsFleetItems.length) {
				self.smsFleetItems.forEach((item) => {
					db.collection('trucks').doc(self.currentTruckID).collection('smsFleetStartupItems').doc(item.id).set({
						id: item.id,
						title: item.title ? item.title : '',
						details: item.details ? item.details : '',
            index: item.index ? item.index : 999,
					});
				});
			}

      var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
      addTruckRevision({
        userName: self.firebasedisplayname,
        userID: self.firebaseid,
        truckName: self.currentTruckName,
        truckID: self.currentTruckID,
        revisionDetails: 'Fleet startup checklists updated in SMS',
      });

			this.isActiveAddFleetItems = false;
			NProgress.done();

		},

		launchEditItem: function(itemID,itemTitle,itemDetails) {
			this.editedItem = {};
			this.editedItem.id = itemID;
			this.editedItem.title = itemTitle;
			this.editedItem.details = itemDetails;
			this.isActiveEditItemModal = true;
		},

		saveHelperItems: function() {
			NProgress.start();
			var self = this;
			if (this.selectedItems.length) {
				this.selectedItems.forEach(function(item){
					db.collection("trucks").doc(self.currentTruckID).collection("smsStartupItems").add({
						title: item.title ? item.title : 'No title',
						details: item.details ? item.details : '',
            index: item.index ? item.index : 999,
					})
					.then(() => {
						self.isActiveAddHelperItems = false;
						self.selectedItems = [];
					    // self.loadSavedItems();
					    NProgress.done();
              var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
              addTruckRevision({
                userName: self.firebasedisplayname,
                userID: self.firebaseid,
                truckName: self.currentTruckName,
                truckID: self.currentTruckID,
                revisionDetails: 'Startup checklist item added from helpers',
              });
						})
					.catch(error => {
						console.error("Error adding alert: ", error);
						self.isActiveAddHelperItems = false;
						NProgress.done();
					});
				});		 
			} else {
				console.log("nothing selected");
				this.isActiveAddHelperItems = false;
				NProgress.done();
			}
		},

		saveNewItem: function() {
			NProgress.start();
			var self = this;
			db.collection("trucks").doc(self.currentTruckID).collection("smsStartupItems").add({
				title: self.newitem.title ? self.newitem.title : 'Unnamed item',
				details: self.newitem.details ? self.newitem.details : '',
			})
			.then(() => {
				self.isActiveNewItemModal = false;
				self.newitem = {};
			    // self.loadSavedItems();
			    NProgress.done();
			})
			.catch(error => {
				console.error("Error adding alert: ", error);
				self.isActiveNewItemModal = false;
				NProgress.done();
			});
		},

		saveEditItem: function() {	
			NProgress.start();
			var self = this;

			db.collection("trucks").doc(self.currentTruckID).collection("smsStartupItems").doc(self.editedItem.id).update({
				title: self.editedItem.title,
				details: self.editedItem.details,
			})
			.then(() => {
				self.isActiveEditItemModal = false;
				self.editedItem = {};
			    // self.loadSavedItems();
			    NProgress.done();
			})
			.catch(error => {
				console.error("Error adding alert: ", error);
				self.isActiveEditItemModal = false;
				NProgress.done();
			});

		},
		deleteItem: function(itemID) {      	
			var self = this;  	
			let dialogueMsg = "<h3>Remove this Item?</h3>";
			let dialogueOptions = {
				html: true,
    		//loader: true,
    		okText: 'Yes, remove',
    		cancelText: 'No, go back',
    		message: 'This is a message',
    		backdropClose: true
    	};
    	this.$dialog
    	.confirm(dialogueMsg, dialogueOptions)
    	.then(function() {
    		NProgress.start();	
    		db.collection("trucks").doc(self.currentTruckID).collection("smsStartupItems").doc(itemID).delete()
    		.then(function(){	
    			NProgress.done();
          var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
          addTruckRevision({
            userName: self.firebasedisplayname,
            userID: self.firebaseid,
            truckName: self.currentTruckName,
            truckID: self.currentTruckID,
            revisionDetails: 'Startup checklist item deleted',
          });
    		});
    	})
    	.catch(function() {
    		console.log('Clicked on cancel'); 
    		NProgress.done();
    	});
    },


    deleteFleetItem(itemID) {
      let self = this;
      let dialogueMsg = "<h3>Really remove this item?</h3>";
      let dialogueOptions = {
        html: true,
        //loader: true,
        okText: 'Yes, remove',
        cancelText: 'No, go back',
        message: 'This is a message',
        backdropClose: true
      };
      this.$dialog
        .confirm(dialogueMsg, dialogueOptions)
        .then(function() {
          NProgress.start();
          db.collection("trucks").doc(self.currentTruckID).collection("smsFleetStartupItems").doc(itemID).delete().then(function() {
            NProgress.done();
          });
        })
        .catch(function() {
          console.log('Clicked on cancel');
          NProgress.done();
        });
    },

    backToSms: function() {
    	let self = this;
    	//console.log(self.currentTruckID,self.currentTruckName,self.currentTruckUVI);
    	this.$router.push({ name: 'EditSms', params: { 
    		selectedTruckID: self.currentTruckID,
    		selectedTruckName: self.currentTruckName,
    		selectedTruckUVI: self.currentTruckUVI,
    	} })
    },

  }

}





</script>




<style lang="scss" scoped>


textarea {
	// min-height: 100px !important;
	box-shadow: none;
	padding: 0.6rem 1rem;
}

.checkbox-wrapper {
	margin-bottom: 1rem;
	width: 100%;
	border-bottom: 1px dashed #eee;
	padding-bottom: 10px;
	.checkbox-content {
		float: left;
		width: calc(100% - 90px);
	}
}
.header-image {
	// background: url('../assets/img/placeholder-2.jpg') no-repeat center center;
	color: #fff;
}


</style>
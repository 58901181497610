<template>

	<div class="site-wrapper no-header">

		
	
		<div class="container">

      <div class="buttons">
        <!-- <button class="button is-skip" @click="nextStep()">Skip to Part 9 &nbsp;<span><font-awesome-icon icon="chevron-right" /></span></button> -->
        <button class="button is-info" @click="goBack()"><span><font-awesome-icon icon="chevron-left" /></span> &nbsp;Back &nbsp;</button>
      </div>

        <div class="before-icon"><font-awesome-icon icon="calendar-check" /></div><h4 class="icon">{{editing ? 'Edit' : 'Add a'}} Daily Task</h4>  
        <div class="clear"></div>       

			  <div class="">

            <div class="tags has-addons are-medium">
              <span class="tag">Task Status:</span>
              <span class="tag" :class="[newTask.status == 'Active' ? 'active' : newTask.status == 'Complete' ? 'complete' : 'pending']">{{newTask.status}}</span>
            </div>
            
            <p v-if="newTask.status == 'Active'">Accepted by: {{newTask.acceptedBy}} on {{newTask.acceptedAt}}</p>
            <p v-if="newTask.status == 'Complete'">Completed by: {{newTask.finishedBy}} on {{newTask.finishedAt}}</p>

            <div v-if="newTask.status == 'Active' || newTask.status == 'Complete'" class="spacer s20"></div>

	          <div class="form-group-reg">   
              <!-- <label class="label-fixed">Alert Title:</label>    -->
              <input :disabled="archived" class="input" type="text" placeholder="Task title..." v-model="newTask.title">
            </div>
	          <div class="form-group">      
              <textarea :disabled="archived" v-model="newTask.details" class="textarea" placeholder="Details (optional)" rows="5" maxlength="200"></textarea>
            </div>

            <!-- <label class="priority">Task Priority:</label>

            <div class="select priority" :class="newTask.priority">
              <select :disabled="archived" v-model="newTask.priority">
                <option disabled value="">Task Priority</option>
                <option v-for="priority in taskPriority" :value="priority">{{ priority }}</option>
              </select>
            </div> -->

            <input :disabled="archived" class="is-checkradio" id="isPriority" type="checkbox" v-model="newTask.priority">
            <label for="isPriority">This is a priority task</label>
            

            <div class="spacer s30"></div>

            <div v-if="!editing" class="">

              <p class="assign" v-if="selectedTrucks.length">Assigning this task to <span>{{truckNames}}</span></p>
              <p class="assign" v-else>No trucks selected yet.</p>

             <!--  <div class="buttons">
                <button class="button is-light" @click="showTrucks = true">{{selectedTrucks.length ? 'Assign to other trucks' : 'Assign task to one or more trucks'}}</button>
              </div> -->

              <div class="spacer s10"></div>


            </div>

            <!-- <div class="spacer s10"></div> -->

            <hr>

						<!-- <div class="form-group">   
							<span>&nbsp;</span>
							<datetime v-model="newTask.expiryTask"></datetime>   
	            <span class="highlight"></span>
	            <span class="bar"></span>
	            <label>Task:</label>
	          </div> -->
	          
            <div class="buttons">
           		<button :disabled="canSave || archived" class="button is-primary" @click="saveNewTask()">{{saveText}}</button>
              <button v-if="editing && !archived" class="button is-warning" @click="archiveTask()">Archive Task</button>
              <button v-if="editing && archived" class="button is-success" @click="restoreTask()">Restore Task</button>
              <button v-if="editing" class="button is-danger" @click="deleteTask()">Delete Permanently</button>

            </div>


			  </div>



        <div class="modal modal-full-screen modal-fx-fadeInScale" :class="{'is-active':showTrucks}">
          <div class="modal-background" @click="close"></div>
          <div class="modal-card">
              <header class="modal-card-head">
                <p class="modal-card-title">New truck log</p>
                <button class="delete" aria-label="close" @click.prevent="close"></button>
              </header>
              <section class="modal-card-body">   
                
                <div class="checkbox-wrapper">
                  <div class="field" v-for="truck in fleetTrucks" :key="truck.truckID">
                    <input class="is-checkradio" type="checkbox" :id="truck.truckID" :value="truck" v-model="selectedTrucks" />
                    <label :for="truck.truckID">{{ truck.truckName }}</label>
                  </div>
                </div>

              </section>
              <footer class="modal-card-foot">
                <button class="button is-primary" @click.prevent="close">Done</button>
              </footer>
          </div>
        </div>


			  

		</div>


	</div>
	
			

</template>

<script>	

	import { db } from '../main.js';
	import firebase from 'firebase/app';
	import 'firebase/auth'; 
	import 'firebase/firestore';
	import NProgress from 'nprogress';
	import * as moment from 'moment'

	export default {

		name: 'AddTask',
    props: {
      sentTask: Object,
      editing: Boolean,
      archived: Boolean,
      singleTruck: Boolean
    },
		mounted: function() {
      let self = this;
			firebase.auth().onAuthStateChanged( this.onUserLogin );		
      this.newTask.id = this.sentTask.id ? this.sentTask.id : ''; 
      this.newTask.title = this.sentTask.title;	
      this.newTask.details = this.sentTask.details; 
      this.newTask.priority = this.sentTask.priority ? this.sentTask.priority : false; 
      this.newTask.status = this.sentTask.status ? this.sentTask.status : 'Pending'; 
      this.newTask.acceptedBy = this.sentTask.acceptedBy ? this.sentTask.acceptedBy : ''; 
      this.newTask.acceptedAt = this.sentTask.acceptedAt == undefined || this.sentTask.acceptedAt == '' ? '' : this.formatTask(this.sentTask.acceptedAt.toISOString(),"D MMMM YYYY (hh:ma)");
      this.newTask.finishedBy = this.sentTask.finishedBy ? this.sentTask.finishedBy : ''; 
      this.newTask.finishedAt = this.sentTask.finishedAt == undefined || this.sentTask.finishedAt == '' ? '' : this.formatTask(this.sentTask.finishedAt.toISOString(),"D MMMM YYYY (hh:ma)");
      if (this.singleTruck) {
        this.selectedTrucks.push({
          truckID: self.currentTruckID,
          truckUVI: self.currentTruckUVI,
          truckName: self.currentTruckName,
        });
      }
      // let tempTask = moment.utc(this.sentTask.expiryTask);
      // let fixedTask = this.formatTask(tempTask, "D MMM YYYY");
      // console.log('Fixed:',fixedTask);
      // this.newTask.expiryTask = fixedTask; 
      // if (this.sentTask.expiryTask == undefined) {
      //   console.log('empty');
      // } else {
      //   console.log('loaded');
      // }
      // this.newTask.expiryTask = this.sentTask.expiryTask == undefined ? '' : this.sentTask.expiryTask.toISOString();
		},
		computed: {
      currentFleet() {
        return this.$store.getters.getCurrentFleet;
      },
      currentTruckID() {
        return this.$store.getters.getCurrentTruckID;
      },
      currentTruckName() {
        return this.$store.getters.getCurrentTruckName;
      },
      currentTruckUVI() {
        return this.$store.getters.getCurrentTruckUVI;
      },
      userData() {
        return this.$store.getters.getSubscriptionInfo;
      },
      truckNames() {
        return this.selectedTrucks.map((truck) => truck.truckName).join(', ');
      },
      saveText() {
        if (this.editing) {
          return this.newTask.title == '' ? 'Add a title' : 'Save Task';
        } else {
          return this.newTask.title == '' ? 'Add a title' : this.selectedTrucks.length == 0 ? 'Add a truck' : 'Save Task';
        }
      },
      canSave() {
        if (this.editing) {
          return this.newTask.title == '';
        } else {
          return this.newTask.title == '' || this.selectedTrucks.length == 0;
        }
      },
      selectedTruckIDs() {
        return this.selectedTrucks.map((truck) => truck.truckID);
      }
		},
		data: function() {
			return {
				msg: 'Add Alert',
				firebaseid: '',
				newTask: {
          id: '',
          title: '',
          details: '',
          priority: false,
          status: 'Pending',
          acceptedBy: '',
          acceptedAt: '',
          finishedBy: '',
          finishedAt: '',
        },
        // taskPriority: ['Low','Medium','High'],
        selectedTrucks: [],
        showTrucks: false,
        fleetTrucks: [],
        listOfDriverIDs: [],
			}
		},
		methods: {
			onUserLogin: function( user ) {
        this.loadFleetTrucks();
      	if (user) {      		
	      	this.firebaseid = user.uid;
	      }
	    },

      async loadFleetTrucks() {
        let self = this;
        // db.collection('fleets').doc(this.currentFleet.id).collection('fleetTrucks').onSnapshot(function(querySnapshot) {
        //   self.fleetTrucks = [];
        //   querySnapshot.forEach((doc) => {
        //       self.fleetTrucks.push({
        //         truckID: doc.id,
        //         truckName: doc.data().truckName,
        //         truckUVI: doc.data().truckUVI,
        //       });
        //   });
        // });

        await db.collection('fleets').doc(this.currentFleet.id).collection('fleetTrucks').get().then(function(querySnapshot) {
          self.fleetTrucks = [];
          querySnapshot.forEach((doc) => {
              self.fleetTrucks.push({
                truckID: doc.id,
                truckName: doc.data().truckName,
                truckUVI: doc.data().truckUVI,
              });
          });
        })
        .then(() => {
          // self.loadTruckDrivers();  
        });

      },

      // loadTruckDrivers() {
      //   let self = this;
      //   self.listOfDriverIDs = [];
      //   self.fleetTrucks.forEach((truck) => {
      //     db.collection('trucks').doc(truck.truckID).get().then((doc) => {
      //       self.listOfDriverIDs.push(doc.data().listOfStaffIDs);
      //     });
      //   });
      // },

      formatTask : function (sentTask,format) {
          return moment(sentTask).format(format);
      },

      close() {
        this.showTrucks = false;
      },

			async saveNewTask() {
      	NProgress.start();
      	var self = this;

        if (self.editing) {

            db.collection('trucks').doc(self.currentTruckID).collection('dailyTasks').doc(self.newTask.id).update({
                title: self.newTask.title ? self.newTask.title : '',
                details: self.newTask.details ? self.newTask.details : '',
                priority: self.newTask.priority ? self.newTask.priority : false,
                status: self.newTask.status ? self.newTask.status : 'Pending',
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                archived: false,
            })
            .then(() => {
                var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
                addTruckRevision({
                  userName: self.userData.username,
                  userID: self.firebaseid,
                  truckName: self.currentTruckName,
                  truckID: self.currentTruckID,
                  revisionDetails: 'New daily task called ' + self.newTask.title + ' updated',
                });
                NProgress.done();
                self.goBack();
            })
            .catch(error => {
                console.error("Error editing task: ", error);
                NProgress.done();
                self.goBack();
            });

        } else {

            await Promise.all(self.selectedTrucks.map(async (truck) => {
              console.log('Truck:',truck.truckID);
              await db.collection('trucks').doc(truck.truckID).collection('dailyTasks').add({
                title: self.newTask.title ? self.newTask.title : '',
                details: self.newTask.details ? self.newTask.details : '',
                priority: self.newTask.priority ? self.newTask.priority : false,
                status: self.newTask.status ? self.newTask.status : 'Pending',
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                archived: false,
              })
              .then(function() {
                var sendNewTaskNotification = firebase.functions().httpsCallable('sendNewTaskNotification');
                sendNewTaskNotification({
                  taskTitle: self.newTask.title,
                  selectedTruckIDs: self.selectedTruckIDs,
                  selectedTrucks: self.selectedTrucks
                });
              })
              .then(function() {
                var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
                addTruckRevision({
                  userName: self.userData.username,
                  userID: self.firebaseid,
                  truckName: truck.truckName,
                  truckID: truck.truckID,
                  revisionDetails: 'New daily task called ' + self.newTask.title + ' added',
                });
              });
                
            }));

            
            
            NProgress.done();
            self.goBack();

        }

      	

      },

      archiveTask() {
        let self = this;    
        let message = {
          title: 'Archive this task?',
          body: ''
        };
        let options = {
          okText: 'Yes, Archive',
          backdropClose: true,
          cancelText: 'Cancel',
        };
        this.$dialog
          .confirm(message, options)
          .then(function() {

              NProgress.start();  
            
              db.collection('trucks').doc(self.currentTruckID).collection('dailyTasks').doc(self.newTask.id).update({
                archived: true
              })
              .then(() => {
                  var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
                  addTruckRevision({
                    userName: self.userData.username,
                    userID: self.firebaseid,
                    truckName: self.currentTruckName,
                    truckID: self.currentTruckID,
                    revisionDetails: 'Daily task called ' + self.newTask.title + ' archived',
                  });
                  NProgress.done();
                  self.goBack();
              })
              .catch(error => {
                  console.error("Error archiving task: ", error);
                  NProgress.done();
                  self.goBack();
              });
              
          })
          .catch(function() {
            console.log('Clicked on cancel'); 
            NProgress.done();
          });
      },

      restoreTask() {
        let self = this;    
        let message = {
          title: 'Restore this task?',
          body: ''
        };
        let options = {
          okText: 'Yes, Restore',
          backdropClose: true,
          cancelText: 'Cancel',
        };
        this.$dialog
          .confirm(message, options)
          .then(function() {

              NProgress.start();  
            
              db.collection('trucks').doc(self.currentTruckID).collection('dailyTasks').doc(self.newTask.id).update({
                archived: false
              })
              .then(() => {
                  var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
                  addTruckRevision({
                    userName: self.userData.username,
                    userID: self.firebaseid,
                    truckName: self.currentTruckName,
                    truckID: self.currentTruckID,
                    revisionDetails: 'Daily task called ' + self.newTask.title + ' restored',
                  });
                  NProgress.done();
                  self.goBack();
              })
              .catch(error => {
                  console.error("Error restoring task: ", error);
                  NProgress.done();
                  self.goBack();
              });
              
          })
          .catch(function() {
            console.log('Clicked on cancel'); 
            NProgress.done();
          });
      },

      deleteTask() {
        // console.log('Deleting task...', this.newTask.id);
        let self = this;    
        let message = {
          title: 'Delete this task?',
          body: ''
        };
        let options = {
          okText: 'Yes, Delete',
          backdropClose: true,
          cancelText: 'Cancel',
        };
        this.$dialog
          .confirm(message, options)
          .then(function() {

              NProgress.start();  
            
              db.collection('trucks').doc(self.currentTruckID).collection('dailyTasks').doc(self.newTask.id).delete()
              .then(() => {
                  var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
                  addTruckRevision({
                    userName: self.userData.username,
                    userID: self.firebaseid,
                    truckName: self.currentTruckName,
                    truckID: self.currentTruckID,
                    revisionDetails: 'Important task deleted',
                  });
                  NProgress.done();
                  self.goBack();
              })
              .catch(error => {
                  console.error("Error deleting task: ", error);
                  NProgress.done();
                  self.goBack();
              });
              
          })
          .catch(function() {
            console.log('Clicked on cancel'); 
            NProgress.done();
          });
      },


      goBack: function() {
        // console.log('Router',this.$router);
        this.$router.go(-1);
      },


		},

	}

</script>



<style lang="scss" scoped>

	.message {
		.message-body {
			background: #fff;
		}
	}
	textarea {
		min-height: 100px !important;
		box-shadow: none;
    padding: 0.6rem 1rem;
    &.notes {
    	margin-top: 1rem;
    }
	}
  label.priority {
    font-weight: bold;
    margin-right: 10px;
    vertical-align: sub;
  }
  // h4.icon {
  //   margin-bottom: 1rem;
  // }
  .select.priority {
    &.Low {
      select {
        color: green;
        border-color: green;
      }
      &:after {
        border-color: green;
      }
    }
    &.Medium {
      select {
        color: orange;
        border-color: orange;
      }
      &:after {
        border-color: orange;
      }
    }
    &.High {
      select {
        color: red;
        border-color: red;
      }
      &:after {
        border-color: red;
      }
    }
    select {
      text-transform: uppercase;
      &:focus {
        box-shadow: none;
      }
    }
  }

  p {
    &.assign {
      span {
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }
  .tags {
    .tag {
      &.pending {
        background-color: cornflowerblue;
        color: #fff;
      }
      &.active {
        background-color: orange;
        color: #fff;
      }
      &.complete {
        background-color: green;
        color: #fff;
      }
    }
  }


</style>